import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
import HeroImage from '../../assets/hero/talha-anis.png';
//Components
import Button from '../ui-components/button/button';

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={6} lg={12}>
          <div className="hero-image">
            <img src={HeroImage} alt="profile" />
          </div>
        </Col>
        <Col md={6} lg={12}>
          <div className="hero-info">
            <h1 className="font15">HEY THERE !</h1>
            <h1 className="font-info">
              I AM TALHA ANIS
            </h1>
            <p className="font14">
              Founder & Full-Stack Developer of <a href="http://codepy.io">Codepy.io</a> and <a href="http://hostingwp.io">Hostingwp.io</a>
            </p>
            <Button label="SEND MESSAGE" target={"contact"} />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;