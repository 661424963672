import React from "react";
import Swiper from "react-id-swiper";
import {isMobile} from 'react-device-detect';
// SCSS
import "swiper/css/swiper.css";
// Components
import PartnerBox from "./partnerBox";
// Assets
import Partner01 from "../../assets/partners/devops.svg";
import Partner02 from "../../assets/partners/aws.svg";
import Partner03 from "../../assets/partners/docker.svg";
import Partner04 from "../../assets/partners/golang.svg";
import Partner05 from "../../assets/partners/python-3.svg";
import Partner06 from "../../assets/partners/react.svg";
import Partner07 from "../../assets/partners/html5.svg";
import Partner08 from "../../assets/partners/javascript.svg";
import Partner09 from "../../assets/partners/bootstrap.svg";
import Partner10 from "../../assets/partners/nginx.svg";
import Partner11 from "../../assets/partners/mysql.svg";
import Partner12 from "../../assets/partners/mongodb.svg";
import Partner13 from "../../assets/partners/postgres.svg";
import Partner14 from "../../assets/partners/puppet.svg";
import Partner15 from "../../assets/partners/terraform.svg";
import Partner16 from "../../assets/partners/ansible.svg";

import Partner17 from "../../assets/partners/cloudflare.svg";
import Partner18 from "../../assets/partners/grpc.svg";


class MutipleSlidesPerView extends React.Component {
  state = {
    partners: [
      {
        preview: Partner01,
        id: "1",
      },
      {
        preview: Partner02,
        id: "2",
      },
      {
        preview: Partner03,
        id: "3",
      },
      {
        preview: Partner04,
        id: "4",
      },
      {
        preview: Partner05,
        id: "5",
      },
      {
        preview: Partner06,
        id: "6",
      },
      {
        preview: Partner07,
        id: "7",
      },
      {
        preview: Partner08,
        id: "8",
      },
      {
        preview: Partner09,
        id: "9",
      },
      {
        preview: Partner10,
        id: "10",
      },
      {
        preview: Partner11,
        id: "11",
      },
      {
        preview: Partner12,
        id: "12",
      },
      {
        preview: Partner13,
        id: "13",
      },
      {
        preview: Partner14,
        id: "14",
      },
      {
        preview: Partner15,
        id: "15",
      },
      {
        preview: Partner16,
        id: "16",
      },
      {
        preview: Partner17,
        id: "17",
      },
      {
        preview: Partner18,
        id: "18",
      },
    ],
  };

  render() {
    let partnersRender = null;

    if (this.state.partners) {
      partnersRender = this.state.partners.map((partner) => (
        <div key={partner.id}>
          <PartnerBox partner={partner.preview} />
        </div>
      ));
    }

    var slidesPerView = 5;
    var delay = 1500;
    if (isMobile){
      slidesPerView = 2
      delay = 1000;
    }
    const params = {
      grabCursor: true,
      slidesPerView: slidesPerView,
      spaceBetween: 10,
      loop: true,
      autoplay: {
        delay: delay,
        disableOnInteraction: false
      },
    };
    return <Swiper {...params}>{partnersRender}</Swiper>;
  }
};
export default MutipleSlidesPerView;
