import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contactSocial.scss";
import { SocialIcon } from 'react-social-icons';

const contactSocial = () => (
  <Row center="xs lg">
    <Col xs={3} lg={1} className="contact__social">
      <SocialIcon bgColor="#ea4335" url="mailto:t.anis60.ta@gmail.com"></SocialIcon>
    </Col>
    <Col xs={3} lg={1} className="contact__social">
      <SocialIcon url="https://www.linkedin.com/in/talha-anis/"></SocialIcon>
    </Col>
    <Col xs={3} lg={1} className="contact__social">
      <SocialIcon network="whatsapp" url="https://wa.me/+923355703007"></SocialIcon>
    </Col>
  </Row>
);

export default contactSocial;
