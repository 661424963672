import React from "react";
import "./about.scss";
// Components
import Title from "../ui-components/title/title";
// Assets

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="ABOUT ME" />
      <p className="info">
      Backend Developer and DevOps Engineer with years of hands-on experience in implementing 
      highly available and scalable applications and architectures 
      using a range of technologies and programming languages.
      </p>
      <br></br>
      <div className="desktopSkills">
        <p><b>Languages:</b> Golang, Python, Shell, ReactJS, JavaScript, HTML, CSS, Bootstrap</p>
        <p><b>API interface:</b> REST API, gRPC, JSON-RPC, Websocket, Socket.io</p>
        <p><b>Security:</b> TLS, Authentication(oAUTH2, Auth0, BasicAuth), Authorization</p>
        <p><b>DBMS:</b> MySQL, PostgreSQL, MongoDB, AWS DynamoDB, AWS RDS, LevelDB</p>
        <p><b>Containerization:</b> Docker, Kubernetes, AWS ECS, AWS EKS, AWS ECR</p>
        <p><b>DevOps:</b> Linux, Puppet, Ansible, Terraform, Nginx, Apache-II, Cron, Regex</p>
        <p><b>Cloud Providers:</b> GCP, AWS, Azure, Digital Ocean, Vultur, Contabo</p>
        <p><b>AWS:</b> EC2, ELB, Lambda, API Gateway, S3, S3-website, CloudWatch, IAM, CLI, Route 53, ACM, CloudFront</p>
        <p><b>CI/CD:</b> GitlabCI, BitbucketCI, CircleCI, Github Actions, Webhook based custom pipelines</p>
        <p><b>Networking:</b> Iptables, Firewalls, Wireshark</p>
        <p><b>Server administration:</b> Linux (Ubuntu, Debian), Windows, macOS</p>
        <p><b>Git:</b> Bitbucket, Github, Gitlab</p>
        <p><b>Teamwork:</b> Agile, Scrum, Jira, Slack</p>
      </div>
      <div className="mobileSkills">
        <b>Languages</b> <p> Golang, Python, Shell, ReactJS, JavaScript, HTML, CSS, Bootstrap</p>
        <b>API interface</b> <p> REST API, gRPC, JSON-RPC, Websocket, Socket.io</p>
        <b>Security</b> <p> TLS, Authentication(oAUTH2, Auth0, BasicAuth), Authorization</p>
        <b>DBMS</b> <p> MySQL, PostgreSQL, MongoDB, AWS DynamoDB, AWS RDS, LevelDB</p>
        <b>Containerization</b> <p> Docker, Kubernetes, AWS ECS, AWS EKS, AWS ECR</p>
        <b>DevOps</b> <p> Linux, Puppet, Ansible, Terraform, Nginx, Apache-II, Cron, Regex</p>
        <b>Cloud_Providers</b> <p> GCP, AWS, Azure, Digital Ocean, Vultur, Contabo</p>
        <b>AWS</b> <p> EC2, ELB, Lambda, API Gateway, S3, S3-website, CloudWatch, IAM, CLI, Route 53, ACM, CloudFront</p>
        <b>CI/CD</b> <p> GitlabCI, BitbucketCI, CircleCI, Github Actions, Webhook based custom pipelines</p>
        <b>Networking</b> <p> Iptables, Firewalls, Wireshark</p>
        <b>Server_administration</b> <p> Linux (Ubuntu, Debian), Windows, macOS</p>
        <b>Git</b> <p> Bitbucket, Github, Gitlab</p>
        <b>Teamwork</b> <p> Agile, Scrum, Jira, Slack</p>
      </div>
    </div>
  </div>
);

export default about;
